import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Col, Row, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog as faUserCogReg } from "@fortawesome/pro-regular-svg-icons";
import { accountChangeNameRequest, accountChangeNameReceive } from "../actions";
import NavigateButton from "./NavigateButton";
import { Trans } from "gatsby-plugin-react-i18next";

// FUTURE use translations from feature branch UW-221

/**
 * Form to change the user's first and last name
 * @param {any} dispatch - dispatches actions to trigger state changes
 * @param {Int} account_user_id - current user id
 * @param {string} account_email - current user email address
 * @param {string} account_first_name - current user first name
 * @param {string} account_last_name - current user last name
 * @param {string} account_jwt - bearer token
 * @param {Object} account_change_name_response - response from server after name change
 */
const AccountChangeName = ({
  dispatch,
  account_user_id,
  account_email,
  account_first_name,
  account_last_name,
  account_change_name_response,
}) => {
  const [formState, setFormState] = useState({
    email: account_email,
    first_name: account_first_name || "",
    last_name: account_last_name || "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(accountChangeNameRequest(formState, account_user_id));
  };

  const handleChange = (event, key) =>
    setFormState({
      ...formState,
      [key]: event.target.value,
    });

  const beforeNavigate = () => {
    dispatch(accountChangeNameReceive({}));
  };

  const { success, data } = account_change_name_response || {};

  function renderSuccessAlert() {
    return (
      <>
        <p className="alert alert-success">
          Account has been updated successfully to{" "}
          {`${account_first_name} ${account_last_name}`}
        </p>
        <NavigateButton
          beforeNavigate={beforeNavigate}
          path="/account/"
          buttonText="&lt; Back to Account"
        />
      </>
    );
  }

  function renderChangeNameForm() {
    if (success === true) {
      return renderSuccessAlert();
    }

    return (
      <>
        {success === false && data && data.detail && (
          <Alert variant="danger">{data.detail}</Alert>
        )}
        <Form.Group controlId="formFirstName">
          <Form.Label>
            <Trans>First name</Trans>
          </Form.Label>
          <Form.Control
            type="text"
            autoComplete="first_name"
            isInvalid={success === false && data && data.first_name}
            value={formState.first_name}
            onChange={(e) => handleChange(e, "first_name")}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.first_name &&
              data.first_name.length &&
              data.first_name.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formLastName">
          <Form.Label>
            <Trans>Last name</Trans>
          </Form.Label>
          <Form.Control
            type="text"
            autoComplete="last_name"
            isInvalid={success === false && data && data.last_name}
            value={formState.last_name}
            onChange={(e) => handleChange(e, "last_name")}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.last_name &&
              data.last_name.length &&
              data.last_name.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mt-4">
          <Button variant="secondary" type="submit">
            <Trans>Submit</Trans>
          </Button>
          <NavigateButton
            wrapperClass="d-inline"
            buttonClass="btn btn-outline-secondary ml-3"
            path="/account/"
            buttonText="Cancel"
            beforeNavigate={beforeNavigate}
          />
        </Form.Group>
      </>
    );
  }

  return (
    <div className="px-md-4 account-container">
      <Row>
        <Col>
          <h2 className="py-4">
            <FontAwesomeIcon icon={faUserCogReg} className="mr-3" fixedWidth />
            <Trans>Account profile</Trans>
          </h2>
          <h6 className="mb-4 h5">
            <Trans>Change Your Name</Trans>
          </h6>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} noValidate>
        {renderChangeNameForm()}
      </Form>
    </div>
  );
};

AccountChangeName.propTypes = {
  dispatch: PropTypes.any,
  account_user_id: PropTypes.number,
  account_email: PropTypes.string,
  account_first_name: PropTypes.string,
  account_last_name: PropTypes.string,
  account_change_name_response: PropTypes.object,
};

export default AccountChangeName;
